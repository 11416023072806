import React, {useCallback, useEffect, useRef, useState} from "react";
import _ from "lodash";
import {RootState} from "@store";
import {defaultBonus, TBonus} from "@entity/bonus";
import {arraySplice} from "@shared/helpers";
import lodash from "lodash";
import {TPlace} from "@entity/place";
import {get} from "@shared/network";
import {Col, Row} from "react-grid-system";
import {isAndroid, isIOS, isMobile} from "react-device-detect";

type TProps = {
    places: RootState["places"];
    bonus: TBonus[];
    selected: TBonus;
    security: RootState["securityService"];
    setSelected: (selected: TBonus) => void;
}

const Places: React.FC<TProps> = ({
    places, bonus, selected, security,
    setSelected
}) => {
    const [place, setPlace] = useState<TPlace>()
    const [activeBonuse, setActiveBonuse] = useState<TBonus[]>([])
    const filter = lodash.filter(places.items, i => !i.bonusLink !== true),
        placesSplice = arraySplice(filter);

    const ref = useRef<HTMLDivElement>(null)

    const getACard = useCallback((place: TPlace) => {
        get({route: `api/log/clickBonusPage/${place.id}`, token: security.token});
    }, [])

    const getPlacesByBonus = (): TPlace | undefined => {
        return lodash.find(places.items, p => p.id === selected.restaurant);
    }

    useEffect(() => {
        setPlace(getPlacesByBonus());
        const activeBonuseArr: TBonus[] = bonus.filter((b: TBonus) => b.cardNumber)
        setActiveBonuse(activeBonuseArr)
    }, [places, bonus, selected])


    return (
        <>
            <div className="places-content" ref={ref}>
                {placesSplice.map((splice, idx) => (
                    <div className="places" key={idx}>
                        {splice.map((place, i) => {
                            const key = _.findIndex(bonus, (b: TBonus) => b.restaurant === place.id),
                                bonusItem = key !== -1 ? bonus[key] : defaultBonus;
                            if (bonusItem.cardNumber === null) {
                                // return (
                                //     <div key={`${idx}-${i}`} className="place" onClick={() => (getACard(place), window.open(place.bonusLink + ""))}>
                                //         <div className={`bg bg-${place.id}`}></div>
                                //         <div className="description">
                                //             <div className={`logo l-${place.id}`}></div>
                                //             получить карту
                                //         </div>
                                //     </div>
                                // )

                                return (
                                    <div key={`${idx}-${i}`} className="place">
                                        <div className={`bg bg-${place.id}`}></div>
                                        <div className="description">
                                            <div className={`logo l-${place.id}`}></div>
                                            нет карты
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <>
                                    {place !== undefined && isMobile && activeBonuse.length !== 0 && selected.restaurant === place.id ? (
                                        <div key={idx}>
                                            <div key={`${idx}-${i}`} className={`place ${selected.restaurant === place.id ? "selected" : ""}`} onClick={() => {
                                                setSelected(bonusItem)
                                            }}>
                                                <div className={`bg bg-${place.id}`}></div>
                                                <div className="description">
                                                    <div className={`logo l-${place.id}`}></div>
                                                    {bonusItem.bonusSum} бонусов
                                                </div>
                                            </div>
                                            <div className="description-card">
                                                <div className="card-info">
                                                    <div className="place-name">{place?.name}</div>
                                                    <div className="card-number">Карта № {selected.cardNumber}</div>
                                                    <div>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <div className="info-box">
                                                                    <div className="caption red-color">
                                                                        {selected.bonusSum}<br />
                                                                        бонусов
                                                                    </div>
                                                                    <div className="signature">На вашей карте</div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={isMobile ? 12 : 6}>
                                                                <div className="info-box">
                                                                    <div className="caption">{selected.bonusProcent}%</div>
                                                                    <div className="signature">Статус карты</div>
                                                                </div>
                                                            </Col>
                                                            {/* <Col xs={12}>
                                                                <div className="info-box">
                                                                    <div className="caption">{selected.bonusRemainder} р.</div>
                                                                    <div className="signature">
                                                                        До следующего уровня<br />
                                                                        осталось потратить
                                                                    </div>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </div>
                                                    <div className="add-card">
                                                        <div className="caption">Добавить бонусную карту в кошелёк</div>
                                                        <div className="buttons">
                                                            {isAndroid && <button style={{backgroundColor: '#dbdbdb'}} className="gpay" type="button" onClick={() => (getACard(place), window.open(selected.cardHash + ""))} />}
                                                            {isIOS && <button style={{backgroundColor: '#dbdbdb'}} className="wallet" type="button" onClick={() => (getACard(place), window.open(selected.cardHash + ""))} />}
                                                        </div>
                                                    </div>
                                                    <div className="link-offer">
                                                        {place !== undefined && place.documentsLink !== null && (
                                                            <a href={`${place.documentsLink}`} rel="noopener noreferrer" title="Ссылка на оферту бонусной программы" target="_blank">
                                                                Ссылка на оферту бонусной программы
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={`${idx}-${i}`} className={`place`} onClick={() => {
                                            setSelected(bonusItem)
                                            window.scrollTo({
                                                top: window.innerHeight,
                                                left: 0,
                                                behavior: "smooth",
                                            })
                                        }}>
                                            <div className={`bg bg-${place.id}`}></div>
                                            <div className="description">
                                                <div className={`logo l-${place.id}`}></div>
                                                {bonusItem.bonusSum} бонусов
                                            </div>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                    </div>
                ))}
            </div>
        </>
    )
}

export default Places;
import React, { useEffect, useLayoutEffect } from "react"
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthorizationLayout from "@shared/layouts/authorization";
import { TDefaultPageProps } from "..";
import { Col, Row } from "react-grid-system";
import { isMobile } from "react-device-detect";
import { Link } from "@reach/router";
import { Dispatch } from "@store";
import { connect } from "react-redux";
import { EnumSenderSecurity, TRegistrationPayload } from "@store/service/security";
import { CheckboxInput, ConfirmationButtons, PasswordInput, PhoneInput } from "@shared/components/formInput";
import { routes } from "AppRouter";
import ConfirmModal from "./_confirmModal";
import { cloneObject } from "@shared/helpers";

// import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useRegistration } from "@storeZusland/useRegistration";

type Props = {
    fnRegistration?: DispatchProps["fnRegistration"]
} & TDefaultPageProps

const validationSchema = Yup.object().shape({
        phone: Yup.string().required("Поле обязательно для заполнения"),
        // password: Yup.string().required("Поле обязательно для заполнения"),
        // passwordConfirm: Yup.string().required("Поле обязательно для заполнения"),
        sender: Yup.number().min(1, "Обязательно для выбора"),
        name: Yup.string().required("Поле обязательно для заполнения"),
        email: Yup.string().email("Некорректный E-mail").required("Поле обязательно для заполнения")
    }),
    getErrorByField = (formik: any, field: string) => {
        return formik.errors[field] && formik.touched[field] ? formik.errors[field] : undefined
    }

const Registration: React.FC<Props> = ({
    fnRegistration
}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [confirmModal, setConfirmModal] = React.useState<{
        message: string,
        visible: boolean
    }>({
        message: "",
        visible: false
    })

    const phone = useRegistration(state => state.phone)

    const initialValues: TRegistrationPayload = {
        id: "",
        phone: phone,
        password: "",
        passwordConfirm: "",
        sender: EnumSenderSecurity.COMMON_SMS,
        remember: true,
        agreement: false,
        reCAPTCHA: "",
    
        email: "",
        name: ""
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: TRegistrationPayload) => {
            formik.setSubmitting(true)
            if (fnRegistration && executeRecaptcha) {
                const reCAPTCHA = await executeRecaptcha("Registration"),
                    data = await fnRegistration({ ...values, reCAPTCHA });

                if (data.error) formik.setSubmitting(false);
                if (data.link) {
                    window.open(data.link);
                } else if (data.message) {
                    setConfirmModal({
                        message: data.message,
                        visible: true
                    })
                }
            }
            formik.setSubmitting(false)
        },
    }),
        repeat = async () => {
            // if (!fnRegistration || !executeRecaptcha) return;

            // const reCAPTCHA = await executeRecaptcha("Registration");

            // let values: TRegistrationPayload = {
            //     ...cloneObject(formik.values),
            //     sender: EnumSenderSecurity.COMMON_SMS,
            //     reCAPTCHA
            // };
            // formik.setFieldValue("sender", EnumSenderSecurity.COMMON_SMS, false);

            // const data = await fnRegistration(values);
            // if (data.error) formik.setSubmitting(false);
            // if (data.link) window.open(data.link);
            // else if (data.message) {
            //     setConfirmModal({
            //         message: data.message,
            //         visible: true
            //     })
            // }
        }

    return (
        <AuthorizationLayout title="Регистрация">
            <>
                <div className="form-caption">Регистрация</div>
                {/* <form onSubmit={formik.handleSubmit}>
                    <PhoneInput
                        placeholder="+7 (000) 000 00 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")}/>
                    <PasswordInput
                        placeholder="Придумайте пароль"
                        value={formik.values.password}
                        onChange={(value: string) => formik.setFieldValue("password", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "password")}/>
                    <PasswordInput
                        placeholder="Подтверждение пароля"
                        value={formik.values.passwordConfirm}
                        onChange={(value: string) => formik.setFieldValue("passwordConfirm", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "passwordConfirm")}/>
                    <ConfirmationButtons
                        value={formik.values.sender}
                        onChange={value => formik.setFieldValue("sender", value, true)}
                        disabled={[EnumSenderSecurity.Whatsapp]}
                        error={getErrorByField(formik, "sender")}/>
                    <CheckboxInput
                        label="Запомнить пароль"
                        checked={formik.values.remember}
                        disabled={false}
                        onChange={(value: boolean) => formik.setFieldValue("remember", value, false)} />
                    <CheckboxInput
                        label={(
                            <>
                                Согласен с <a href="https://restamanagement.ru/post/documents" target="_blank">обработкой персональных данных</a>
                            </>
                        )}
                        checked={formik.values.agreement}
                        disabled={true}
                        onChange={(value: boolean) => {}}/>

                    // <div className="form-group mb-5">
                      //  <ReCAPTCHA 
                       //     onChange={value => {
                        //        formik.setFieldValue("reCAPTCHA", value)
                         //   }}
                          //  sitekey="6LcVLRobAAAAAJFnOZlhhQiPw0x0H-OW2-Bhyjdt"/>
                   // </div>
                    <div className="form-footer">
                        <button 
                            // disabled={formik.values.reCAPTCHA?formik.isSubmitting:true}
                            data-badge="inline"
                            disabled={formik.isSubmitting}
                            type="submit">
                            Подтвердить
                        </button>
                        <Row>
                            <Col xs={isMobile?12:6}>
                                <Link to={routes.login}>Авторизация</Link>
                            </Col>
                            <Col xs={isMobile?12:6} className="text-right">
                                <Link to={routes.forgotPassword}>Забыли пароль?</Link>
                            </Col>
                        </Row>
                    </div>
                </form> */}
                <div className="form-m">
                    <div className={`form-group ${getErrorByField(formik, "name") && 'danger-input'}`}>
                        <label>Имя</label>
                        <input type="text" name="name" value={formik.values.name} onChange={formik.handleChange} required={true} />
                        <div className="error">{getErrorByField(formik, "name")}</div>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <div className="">Телефон</div>
                        </Col>
                    </Row>
                    <PhoneInput
                        placeholder="+7 (000) 000 00 00"
                        value={formik.values.phone}
                        onChange={(value: string) => formik.setFieldValue("phone", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "phone")} />
                    <div className={`form-group ${getErrorByField(formik, "email") && 'danger-input'}`}>
                        <label>E-mail</label>
                        <input type="text" name="email" value={formik.values.email} onChange={formik.handleChange} required={true} />
                        <div className="error">{getErrorByField(formik, "email")}</div>
                    </div>
                    {/* <PasswordInput
                        placeholder="Придумайте пароль"
                        value={formik.values.password}
                        onChange={(value: string) => formik.setFieldValue("password", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "password")}/>
                    <PasswordInput
                        placeholder="Подтверждение пароля"
                        value={formik.values.passwordConfirm}
                        onChange={(value: string) => formik.setFieldValue("passwordConfirm", value, true)}
                        disabled={formik.isSubmitting}
                        error={getErrorByField(formik, "passwordConfirm")}/> */}
                    <ConfirmationButtons
                        value={formik.values.sender}
                        onChange={value => formik.setFieldValue("sender", value, true)}
                        disabled={[EnumSenderSecurity.Whatsapp]}
                        error={getErrorByField(formik, "sender")} />
                    {/* <CheckboxInput
                        label="Запомнить пароль"
                        checked={formik.values.remember}
                        disabled={false}
                        onChange={(value: boolean) => formik.setFieldValue("remember", value, false)} /> */}
                    <CheckboxInput
                        label={(
                            <>
                                Согласен с <a href="https://restamanagement.ru/post/documents" target="_blank">обработкой персональных данных</a>
                            </>
                        )}
                        checked={formik.values.agreement}
                        disabled={true}
                        onChange={(value: boolean) => { }} />

                    {/* <div className="form-group mb-5">
                        <ReCAPTCHA 
                            onChange={value => {
                                formik.setFieldValue("reCAPTCHA", value)
                            }}
                            sitekey="6LcVLRobAAAAAJFnOZlhhQiPw0x0H-OW2-Bhyjdt"/>
                    </div> */}
                    <div className="form-footer">
                        <button
                            disabled={formik.isSubmitting && true}
                            data-badge="inline"
                            onClick={() => formik.submitForm()}>
                            Подтвердить
                        </button>
                        <Row>
                            <Col xs={12} style={{ textAlign: "center", fontSize: 14 }}>
                                <Link to={routes.login}>Авторизация</Link>
                            </Col>
                            {/* <Col xs={isMobile ? 12 : 6} className="text-right">
                                <Link to={routes.forgotPassword}>Забыли пароль?</Link>
                            </Col> */}
                        </Row>
                    </div>
                </div>
                {confirmModal.visible && (
                    <ConfirmModal
                        phone={formik.values.phone}
                        visible={confirmModal.visible}
                        caption={confirmModal.message}
                        sender={formik.values.sender}
                        onClose={() => {
                            setConfirmModal({
                                message: "",
                                visible: false
                            });
                        }}
                        onRepeat={repeat} />
                )}
            </>
        </AuthorizationLayout>
    )
}

const mapDispatch = (d: any) => {
    const dispatch = d as Dispatch;
    return {
        fnRegistration: dispatch.securityService.registration,
    }
}
type DispatchProps = ReturnType<typeof mapDispatch>
export default connect(null, mapDispatch)(Registration);
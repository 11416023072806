import React from "react"
import {Link, navigate} from "@reach/router"
import {routes} from "AppRouter"
import {Dispatch, RootState} from "@store"
import {connect} from "react-redux"
import {confirm} from "@shared/interaction"
import {isMobile} from "react-device-detect"

type Props = {
    profile: RootState["profile"]["item"],
    fnLogout: Dispatch["securityService"]["logout"],
    nav?: boolean
}

const DesktopHeader: React.FC<Props> = ({
    profile, fnLogout, nav
}) => {
    const logout = () => {
        if (confirm("Выйти из личного кабинета?")) fnLogout({})
    }
    return (
        <header>
            <div className="caption">

                <a className="rm-logo" href="https://restamanagement.ru/" target="_blank" title="Resta Management" rel="noopener noreferrer" />
                <a className="link-to-lk" href="https://lk.resta.rest" rel="noopener noreferrer">
                    {isMobile? (
                        "ЛК бонусного клуба"
                    ): (
                        "Личный кабинет бонусного клуба"
                    )}
                </a>
            </div>
            <div className="user-info">
                <ul>
                    <li>
                        <Link to={routes.profile}>{profile.name}</Link>
                    </li>
                    <li>
                        {!profile.name ? (

                            <button onClick={() => navigate('/login')}>
                                <i className="fas fa-user"></i>
                            </button>
                        ) : (
                            <button onClick={logout}>
                                <i className="fas fa-sign-out-alt"></i>
                            </button>
                        )}
                    </li>
                </ul>
            </div>
        </header>
    )
}

const mapState = (state: RootState) => ({
    profile: state.profile.item
}),
    mapDispatch = (d: any) => {
        const dispatch: Dispatch = d;
        return {
            fnLogout: dispatch.securityService.logout,
        }
    }
export default connect(mapState, mapDispatch)(DesktopHeader);